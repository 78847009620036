import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { ReactComponent as DriverAvatar } from "assets/images/icons/driver-avatar.svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { getAllTasks } from "apis/driverClient";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { convertTZ, getUSToday } from "utils/utils";
import { ReactComponent as DragAndDrop } from "assets/images/drag-and-drop.svg";
import { ReactComponent as Delivering } from "assets/images/list/current_delivering.svg";
import { ReactComponent as PickingUp } from "assets/images/list/current_picking_up.svg";
import { ReactComponent as WaitingForDelivery } from "assets/images/list/waiting_for_delivery.svg";
import { ReactComponent as WaitingForPickup } from "assets/images/list/waiting_for_pickup.svg";
import { ReactComponent as Succeeded } from "assets/images/map/succeeded.svg"
import { ReactComponent as Failed } from "assets/images/map/failed.svg"

const ItemSelector = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected, // Use the theme palette or any custom color
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.action.hover, // Optionally change color on hover
    },
}));

const SubItemsBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between"
}))

const ListSubItem = styled(Typography)(() => ({
    fontSize: "10px",
    fontWeight: 400,
    maxWidth: "45%",
    overflow: "auto", // Enable scrolling only when necessary
    whiteSpace: "nowrap",
    display: "inline-block",
}));

const DriverListBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const DriverList = ({ driverInfo, currentSelectedDriver, selectedDriver, selectedTask, filteredDate, npiKey, taskIndex, updatedTasks, responseUpdatedTasks }) => {
    const [tasksInfo, setTasksInfo] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null); // Track which item is hovered

    function handleDriverClick() {
        selectedDriver(driverInfo);
        setOpen(!open);
    }

    function setData(task, index) {
        selectedTask(task);
        taskIndex(index);
        setSelectedIndex(index);
    }

    useEffect(() => {
        if (currentSelectedDriver) {
            if (currentSelectedDriver.id !== driverInfo.id) {
                setOpen(false);
            }
        }
    }, [currentSelectedDriver]);

    useEffect(() => {
        if (responseUpdatedTasks && responseUpdatedTasks.length > 0) {
            setTasksInfo(responseUpdatedTasks);
        }
    }, [responseUpdatedTasks]);

    function renderIcon(task) {
        // if (task.is_pickup === 1) {
        //     if (task.state === 2) {
        //         return <PickingUp />
        //     } else {
        //         return <WaitingForPickup />
        //     }
        // } else {
        //     if (task.state === 2) {
        //         return <Delivering />
        //     } else {
        //         return <WaitingForDelivery />
        //     }
        // }
        if (task.state === 1) {
            if (task.is_pickup === 1) {
                return <WaitingForPickup />
            } else {
                return <WaitingForDelivery />
            }
        } else if (task.state === 2) {
            if (task.is_pickup === 1) {
                return <PickingUp />
            } else {
                return <Delivering />
            }
        } else if (task.state === 3) {
            if (task.status === 1) {
                return <Succeeded />
            } else if (task.status === 2) {
                return <Failed />
            }
        }
    }

    async function fetchTasksData() {
        if (npiKey && filteredDate && driverInfo) {
            const response = await getAllTasks(
                driverInfo.onfleet_worker_id,
                npiKey,
                `${filteredDate} 00:00:00`,
                `${filteredDate} 23:59:59`);
            return response;
        }
    }

    async function filterTasksData() {
        const fetchedData = await fetchTasksData();
        if (fetchedData) {
            const tasksData = fetchedData.tasks;
            let newPairs = [];
            const date = new Date();
            const usDate = convertTZ(date, "America/Los_Angeles");
            if (tasksData !== null) {
                for (let i = 0; i < tasksData.length; i++) {
                    const taskDate = new Date(tasksData[i].onfleet_time);
                    if (tasksData[i].status === 1
                        && taskDate.getFullYear() === usDate.getFullYear()
                        && taskDate.getMonth() === usDate.getMonth()
                        && taskDate.getDate() === usDate.getDate()) {
                    } else {
                        newPairs.push(tasksData[i]);
                    }
                }
                setTasksInfo(newPairs);
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            filterTasksData();
        }, 500)
    }, [driverInfo])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            tasksInfo,
            result.source.index,
            result.destination.index
        );

        setTasksInfo(items);
        updatedTasks(items);
    }

    return (
        <DriverListBox>
            {Object.keys(driverInfo).length !== 0 && (
                <div key={driverInfo.id}>
                    <ListItemButton onClick={() => handleDriverClick()}>
                        <ListItemIcon>
                            <DriverAvatar />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1" style={{ fontSize: "18px", fontWeight: 500 }}>
                                    {`${driverInfo.name}`}
                                </Typography>
                            }
                        />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="taskList">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {tasksInfo &&
                                        tasksInfo.map((task, index) => (
                                            (filteredDate === getUSToday()) ? (
                                                task.state === 2 ? (
                                                    <Collapse
                                                        in={open}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <List component="div" disablePadding>
                                                            <ItemSelector
                                                                onMouseEnter={() => setHoveredIndex(index)}
                                                                onMouseLeave={() => setHoveredIndex(null)}
                                                                onClick={() => setData(task, index)}
                                                                selected={selectedIndex === index}
                                                            >
                                                                {window.innerWidth > 600 && (
                                                                    <ListItemIcon style={{ width: "20px", height: "20px", paddingLeft: "20px" }}>
                                                                        {renderIcon(task)}
                                                                    </ListItemIcon>
                                                                )}

                                                                <ListItemText
                                                                    secondary={
                                                                        <Fragment>
                                                                            <Typography
                                                                                sx={{ display: 'inline' }}
                                                                                component="span"
                                                                                variant="body1"
                                                                                style={{ fontSize: "14px", fontWeight: 500 }}
                                                                                color="text.primary"
                                                                            >
                                                                                {index + 1} - {task.destination_address}<br />
                                                                            </Typography>
                                                                            <SubItemsBox>
                                                                                <ListSubItem>
                                                                                    {task.recipient_name}
                                                                                </ListSubItem>
                                                                                <ListSubItem>
                                                                                    {task.onfleet_time}
                                                                                </ListSubItem>
                                                                            </SubItemsBox>
                                                                        </Fragment>
                                                                    }
                                                                />
                                                            </ItemSelector>
                                                        </List>
                                                    </Collapse>
                                                ) : (
                                                    <Draggable
                                                        key={task.id}
                                                        draggableId={`${task.id}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <Collapse
                                                                in={open}
                                                                timeout="auto"
                                                                unmountOnExit
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <List component="div" disablePadding>
                                                                    <ItemSelector
                                                                        onMouseEnter={() => setHoveredIndex(index)}
                                                                        onMouseLeave={() => setHoveredIndex(null)}
                                                                        onClick={() => setData(task, index)}
                                                                        selected={selectedIndex === index}
                                                                    >
                                                                        {window.innerWidth > 600 && (
                                                                            <ListItemIcon style={{ width: "20px", height: "20px", paddingLeft: "20px" }}>
                                                                                {renderIcon(task)}
                                                                            </ListItemIcon>
                                                                        )}

                                                                        {((window.innerWidth > 600 && snapshot && snapshot.isDragging) || hoveredIndex === index) && (
                                                                            <ListItemIcon style={{ width: "20px", position: 'absolute' }}>
                                                                                <DragAndDrop />
                                                                            </ListItemIcon>
                                                                        )}

                                                                        <ListItemText
                                                                            secondary={
                                                                                <Fragment>
                                                                                    <Typography
                                                                                        sx={{ display: 'inline' }}
                                                                                        component="span"
                                                                                        variant="body1"
                                                                                        style={{ fontSize: "14px", fontWeight: 500 }}
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {index + 1} - {task.destination_address}<br />
                                                                                    </Typography>
                                                                                    <SubItemsBox>
                                                                                        <ListSubItem>
                                                                                            {task.recipient_name}
                                                                                        </ListSubItem>
                                                                                        <ListSubItem>
                                                                                            {task.onfleet_time}
                                                                                        </ListSubItem>
                                                                                    </SubItemsBox>
                                                                                </Fragment>
                                                                            }
                                                                        />
                                                                    </ItemSelector>
                                                                </List>
                                                            </Collapse>
                                                        )}
                                                    </Draggable>
                                                )

                                            ) : (
                                                <Collapse
                                                    key={task.id}
                                                    in={open}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <List component="div" disablePadding>
                                                        <ItemSelector
                                                            sx={{ pl: 4 }}
                                                            onClick={() => setData(task, index)}
                                                            selected={selectedIndex === index}
                                                        >
                                                            {window.innerWidth > 600 && (
                                                                <ListItemIcon style={{ width: "20px", height: "20px" }}>
                                                                    {renderIcon(task)}
                                                                </ListItemIcon>
                                                            )}

                                                            <ListItemText
                                                                secondary={
                                                                    <Fragment>
                                                                        <Typography
                                                                            sx={{ display: 'inline' }}
                                                                            component="span"
                                                                            variant="body1"
                                                                            style={{ fontSize: "14px", fontWeight: 500 }}
                                                                            color="text.primary"
                                                                        >
                                                                            {index + 1} - {task.destination_address}<br />
                                                                        </Typography>
                                                                        <SubItemsBox>
                                                                            <ListSubItem>
                                                                                {task.recipient_name}
                                                                            </ListSubItem>
                                                                            <ListSubItem>
                                                                                {task.onfleet_time}
                                                                            </ListSubItem>
                                                                        </SubItemsBox>
                                                                    </Fragment>
                                                                }
                                                            />
                                                        </ItemSelector>
                                                    </List>
                                                </Collapse>
                                            )
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
        </DriverListBox>
    )
}

export default DriverList;